import React, { useEffect } from 'react';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../../Cases/Cases.scss';
import cases from '../../../data/casesInfo.json';
import './CaseFijiv2.scss';
import { Helmet } from 'react-helmet';

export default function CaseFiji() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="caseFiji">
      <Helmet>
        <title>Case Fiji - inte-QT</title>
        <meta name="title" content="Case Fiji - inte-QT" />
        <meta
          name="description"
          content="Connecting a BFSI company in far East of the World, Fiji"
        />
        <link rel="canonical" href="https://inte-qt.com/" />
      </Helmet>
      <img
        src="https://i.imgur.com/diCPBGP.jpg"
        alt="bigbanner-background"
        className="banner-img banner-img__black"
      />
      <h1 className="caseFiji2__title text-center">
      Navigating Connectivity Challenges in Fiji’s Banking Sector
      </h1>
      <div className="caseFiji2__div"></div>

      <div className="container-fluid caseFiji">
        <div className="container">
          <div className="caseFiji2__width my-5">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/847uR6R.png"
                alt="tecnology-head"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Challenges:</h2>
            </div>
            <ul>
              <li>Faster Deployment and Migration</li>
              <li className="mt-2">Secure Access to Cloud Applications</li>
              <li className="mt-2">Integration of Remote offices with the Main-land</li>
              <li className="mt-2">Adverse Climate Conditions</li>
              <li className="mt-2">Unavailability of required infraestructure</li>
            </ul>
          </div>
          <div className="caseFiji2__width my-5 caseFiji2__margin">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/4s6vXof.png"
                alt="wireless-signal"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Solution:</h2>
            </div>
            <ul>
              <li>
                Robust and Reilable Network deployment as part of inte-Qt
                Dedicated Line service
              </li>
              <li className="mt-2">
                inte-Qt Dedicated Line service with flawless installation
                ensured one go live of multiple locations.
              </li>
              <li className="mt-2">
                inte-Qt Dedicated Line service comes with 24x7 Network
                Assurance, which enabled the resolution in real time customer
                operations.
              </li>
            </ul>
          </div>

          <div className="caseFiji2__width my-5">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/55CYPwa.png"
                alt="devices-red"
                className="caseFiji2__icon mx-3"
              />
  
              <h2 className="pt-4">Outcomes:</h2>
            </div>
            <ul>
              <li>Sustainable and seamless operations for the user.</li>
              <li className="mt-2">
                Robust savings for the customer and overall service effectiveness.
              </li>
              <li className="mt-2">Exceeding customer expectations.</li>
            </ul>
          </div>

          <h2 className="my-5">Challenge:</h2>
          <p align="justify">
            We at inte-QT believe that Challenges are what makes
            us stronger every day and better with each delivery.
          </p>
          <p align="justify">
            The customer had requirements to provide connectivity to a reputed
            Bank located in the far east country, Fiji. Fiji, the country of
            islands and where the Sun rises at the time when it is setting
            somewhere in the West.
          </p>
          <img
            src="https://i.imgur.com/Dvbfhyz.jpg"
            alt="light-beach"
            className="caseFiji2__textimg caseFiji2__right m-3"
          />
          <p align="justify">
            The customer and the end-user wanted quick deployment and
            installation of links, since the operations of Banking industry are
            critical in nature therefore the deliveries of the links were
            extremely important to be well within the target timelines. The
            requirement was huge considering the size of the country and we had
            more than a dozen links to be installed within the short delivery
            span. The multi-faceted region and complex logistics involved in
            this was quite a challenging task.
          </p>
          <p align="justify">
            The user operations were critical with restricted access, therefore
            secure and safe service was of the prime importance.
          </p>
          <p align="justify">
            The country is in a seismic zone which is quite prone to natural
            calamities like high intense cyclones which made the access to sites
            and connectivity deployment very difficult.
          </p>
          <p align="justify">
            There were roadblocks like unavailability of required infra at user
            locations, other hindrances like on-time availability of the
            equipments, time difference impacting the sourcing activities and
            the installation of devices without hampering the existing service.
          </p>
          <p align="justify">
            And the commercials had to be much aggressive than their current
            run-rate, in order to ensure savings.
          </p>

          <h2 className="my-5">Solution:</h2>
          <p align="justify">
            Deeper customer engagement has always been a part of culture at Bits
            and Byte Global. The customer does understand and appreciate the
            support they receive from us and this helps them to share their
            requirements and concerns in an open manner.
          </p>
          <img
            src="https://i.imgur.com/4O7Reay.jpg"
            alt="laptop-tecnology"
            className="caseFiji2__textimg caseFiji2__left m-3"
          />

          <p align="justify">
            Understanding the customer needs and complexity of the challenges,
            we started engagement with all the relevant interfaces to make this
            a success. The local channel partners and service providers were
            informed of requirements and criticality of the service, its
            importance. Logistics were aligned in such a way that all
            installation at remote sites were done and integrated with mainland
            without any delay and failure. The relevant teams completed their
            tasks in a fashion that ensure the services to be live and running
            in one go.
          </p>
          <p align="justify">
            The network was designed and deployed in the fashion that it ensures
            safety and security of the end user and customer data to the cloud
            service providers. The redundancies, knowing the geographical
            location which is susceptible to natural calamities specially
            cyclones and heavy rains, were in place before the links were made
            live so that adversaries if any, should not impact the operations of
            the user.
          </p>
          <p align="justify">
            inte-Qt Dedicated Line service has commitment at every front,
            from bandwidth to assurance of 24x7 network support. The teams were
            monitoring the services since provisioning and did rigorous tests
            before declaring it ready for service.
          </p>

          <h2 className="my-5">Outcome:</h2>
          {/* <img src="https://res.cloudinary.com/dql1gaina/image/upload/v1638455669/website/ezgif.com-gif-maker_7_weieon.webp" alt="team" className="caseFiji2__textimg caseFiji2__right m-3"/> */}
          <p align="justify">
            With the collaborative efforts of various cross functional teams ,
            inte-Qt delivered the required services with all the relevant
            quality parameters well ahead of the time. Sustainable operations of
            the end user is the key benefit of inte-Qt Dedicated Line
            service. The various multiple links situated in different parts of
            the country were made live with connectivity to the cloud without
            any loss in service and traffic.
          </p>
          <p align="justify">
            The planning and execution of the service deployment brought key
            monetary savings to the customer and the end user without impacting
            their operations. It is estimated that there were savings of around
            38% against their spend calculated before we completed the project.
            There were no compromises on quality of service, in-fact the design
            was so precise that it brought the effectiveness in the operations
            of the user with prompt monitoring and control.
          </p>
          <p align="justify">
            The customer and the end user appreciated inte-Qt Dedicated Line
            service, flawless execution and impeccable network support which not
            only had tangible benefits but also the intangible gains when it
            comes to service parameters and network quality. Thus, the Happy
            Customer, for which inte-QT Always thrives for, through
            its products and services.
          </p>
          <img
            src="https://i.imgur.com/7fH1NGe.jpg"
            alt="faces"
            className="caseFiji2__lastimg rounded"
          />

          <div className="cases__box">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="cases__companyName mx-3">{cases[2].title}</h2>
                <p className="cases__text m-3" align="justify">
                  {cases[2].p5}
                </p>
                <div className="text-end mx-3">
                  <DinamicLink url="/cases/argentina" name="Read more" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <img src={cases[2].img} alt="1" className="cases__img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
    </div>
  );
}
