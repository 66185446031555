import React, { useEffect } from 'react';
import DinamicLink from '../../../components/DinamicLink/DinamicLink';
import GetInTouch from '../../../components/GetInTouch/GetInTouch';
import '../../Cases/Cases.scss';
import cases from '../../../data/casesInfo.json';
import '../CaseFijiv2/CaseFijiv2.scss';
import { Helmet } from 'react-helmet';

export default function CaseColgate() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="caseFiji">
      <Helmet>
        <title>Case Argentina - inte-QT</title>
        <meta name="title" content="Case Argentina - inte-QT" />
        <meta name="description" content={cases[2].title} />
        <link rel="canonical" href="https://www.inte-qt.com/cases/argentina" />
      </Helmet>
      <img
        src={cases[2].img}
        alt="bigbanner-background"
        className="banner-img banner-img__black"
      />
      <h1 className="banner-img__header text-center">{cases[2].title}</h1>

      <div className="container-fluid caseFiji">
        <div className="container">
          <div className="caseFiji2__width my-5">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/847uR6R.png"
                alt="tecnology-head"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Challenges:</h2>
            </div>
            <ul>
              <li>Faster Deployment amid COVID-19 regulations.</li>
              <li className="mt-2">Stringent Health and Safety compliances.</li>
              <li className="mt-2">
                Strict Quality of Service metrics in a remote location.
              </li>
            </ul>
          </div>
          <div className="caseFiji2__width my-5 caseFiji2__margin">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/4s6vXof.png"
                alt="wireless-signal"
                className="caseFiji2__icon mx-3"
              />
              <h2 className="pt-4">Solution:</h2>
            </div>
            <ul>
              <li>
                Adherence to COVID-19 protocols and regulations for the delivery
                of inte-Qt Dedicated Line.
              </li>
              <li className="mt-2">
                Alignment with the right partner to ensure compliances on health
                and safety are followed without a miss.
              </li>
              <li className="mt-2">
                Robust network design exceeding the customer expectations as
                part of inte-Qt Dedicated Line service.
              </li>
            </ul>
          </div>

          <div className="caseFiji2__width my-5">
            <div className="caseFiji2__space m-4">
              <img
                src="https://i.imgur.com/55CYPwa.png"
                alt="devices-red"
                className="caseFiji2__icon mx-3"
              />

              <h2 className="pt-4">Outcomes:</h2>
            </div>
            <ul>
              <li>
                Delighted customer with delivery ahead of time with all
                regulations and compliances adhered.
              </li>
              <li className="mt-2">
                Better performance on quality-of-service parameters exceeding
                customer expectations.
              </li>
            </ul>
          </div>

          <h2 className="my-5">Challenge:</h2>
          <p align="justify">
            We at inte-QT do have the ‘Passion for Customer
            Delight’.
          </p>
          <p align="justify">
            An existing customer of inte-QT had requirement of
            dedicated internet line in Argentina.
          </p>
          <p align="justify">
            The requirement was for a global FMCG (Fast Moving Consumer Goods)
            giant which is a Fortune 500 company, who has its operations in the
            LATAM region.
          </p>
          <img
            src="https://i.imgur.com/mifydW7.jpg"
            alt="light-beach"
            className="caseFiji2__textimg caseFiji2__right m-3"
          />
          <p align="justify">
            The duration was in Q1 2021 when COVID-19 was resurging in the
            country, customer was expecting to get this deployed amid the
            challenges, in the very short lead times when restrictions were
            there, and compliance requirements were also complicated.
          </p>
          <p align="justify">
            At the time of site survey and during the service delivery, the end
            customer asked us for a list of specific compliance documents which
            were related to COVID-19, Health and Safety requirements for access
            to site for surveys. The compliance requirement was mandatory for
            arranging access for our partner’s survey and installation teams. We
            were aware of our partner’s capabilities so could manage to get the
            documents prepared in shortest possible time. We pushed the
            authorities with our partner for getting the sign off with all terms
            and conditions met.
          </p>
          <p align="justify">
            The operations of the end user being quite precise in nature and for
            this they required the best class of service in terms of bandwidth,
            latency, robust availability, and reliable routes. The quality
            parameters were extremely difficult to maintain in the remote
            location of end user operations.
          </p>

          <h2 className="my-5">Solution:</h2>
          <p align="justify">
            Customer had a quality experience from our last deliveries in the
            region and had confidence in us, that inte-QT team can
            get this done for them in given time frame with best service. There
            was customer engagement with the requirements, we understood the
            emergency of the customer to connect with internet for their
            business operations and expansions in the regions.
          </p>

          <p align="justify">
            inte-QT has a very strong partner center model in the
            Latin America with trusted partner capabilities. The local managed
            service partner and service provider were aligned to COVID-19
            regulations, other health and safety compliances and also the
            timeframe to complete this service delivery.
          </p>
          <p align="justify">
            We took it as a challenge as we believe ‘In the middle of every
            difficulty, lies an opportunity’. The operations team collaborative
            worked with partner as well as the end customer for finalising the
            delivery framework and the required configuration. We engaged with
            partner for speeding up the delivery process.
          </p>
          <p align="justify">
            With limited resources available in COVID times, we found
            communication in local language is essential for fast tracking. We
            then quickly re-aligned ourselves and engagement with local customer
            was put on fast track by assigning a dedicated resource who can
            discuss and execute the delivery in timely manner. We made this
            happen through virtual meetings, video calls etc with end customer,
            partner.
          </p>
          <p align="justify">
            The end customer also appreciated the way which we prepared
            documents and fulfilled compliance form in one go. Going further we
            ensured all safety and precautionary practises to be followed with
            the help of our partner. Our partner made it possible for us and we
            really admire the capabilities and efficiency of the partner.
          </p>

          <img
            src="https://i.imgur.com/rMUNic4.jpg"
            className="caseFiji2__textimg caseFiji2__left m-3"
            alt="jordan"
          />
          <h2 className="my-5">Outcome:</h2>
          <p align="justify">
            Even with difficult situation in the pandemic times and other
            challenges on compliances, we not only delivered the service well
            ahead of time but also with quality parameters both in technical and
            commercial terms.
          </p>
          <p align="justify">
            The quality of service delivered is worth to be called as real
            customer experience, The network was designed in such a way that all
            the difficult quality of service parameters were not only achieved
            but were also exceeded.
          </p>
          <p align="justify">
            The redundancies and assurance parameters were set highly stringent
            to avoid any major impact on the user’s operations which made
            customer and the end user delighted.
          </p>

          <div className="cases__box">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <h2 className="cases__companyName mx-3">{cases[1].title}</h2>
                <p className="cases__text m-3" align="justify">
                  {cases[1].p3}
                </p>
                <div className="text-end mx-3">
                  <DinamicLink url="/cases/lebanon" name="Read more" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <img src={cases[1].img} alt="1" className="cases__img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <GetInTouch />
    </div>
  );
}
